<template>
  <div>
  <ContentHeader title="Pembelian Produk" url="/pembelian-obat" subTitle="Faktur Pembelian" />
     <div class="content-header">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body pl-4" style="display: block;">
           <h4 class="text-center mb-3">FAKTUR PEMBELIAN</h4>
           <div class="row">
             <div class="col">
               <h4>{{penjual.nama}}</h4>
               <p>{{penjual.alamat}}</p>
               <p>Telp. {{penjual.telepon}}</p>
             </div>
           </div>
           <hr style="height:10px;border:none;color:#808080;background-color:#808080;">
           <div class="row">
             <div class="col">
               <p>Nama Klinik : {{pembeli.nama}}</p>
               <p>Alamat      : {{pembeli.alamat}}</p>
               <p>Tanggal     : {{dayjs(data.created_at).format('DD/MM/YYYY')}}</p>
               <p>No. Faktur  : {{data.nomor_faktur}}</p>
             </div>
           </div>

           <table class="table table-bordered mt-4">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Kode Barang</th>
                  <th scope="col">Nama Barang</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Satuan</th>
                  <th scope="col">Harga Satuan</th>
                  <th scope="col">Diskon</th>
                  <th scope="col">Sub Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in data.pembelian_detail" :key="index">
                  <td class="text-sm" scope="row">{{ index + 1 }}</td>
                  <td class="text-sm">{{ row.produk.kode }}</td>
                  <td class="text-sm">{{ row.produk.nama }}</td>
                  <td class="text-sm">{{ row.jumlah }}</td>
                  <td class="text-sm">{{ row.produk.satuan }}</td>
                  <td class="text-sm">{{ format_nominal(row.harga_beli) }}</td>
                  <td class="text-sm">{{ format_nominal(row.diskon) }}</td>
                  <td class="text-sm">{{ format_nominal(row.total) }}</td>
                </tr>
                <tr>
                  <td colspan="3" class="text-center font-weight-bold">Total QTY</td>
                  <td class="font-weight-bold">{{ data.total_qty }}</td>
                  <td colspan="3" class="text-center font-weight-bold">Total</td>
                  <td class="font-weight-bold">{{ format_nominal(data.sub_total) }}</td>
                </tr>
                <tr>
                  <td colspan="4" style="border: none;"></td>
                  <td colspan="3" class="text-center font-weight-bold">Diskon</td>
                  <td class="font-weight-bold">{{ generateNominal(data.diskon, data.sub_total) }}</td>
                  <!-- <td class="font-weight-bold">{{ data.diskon <= 100 ? format_nominal(data.diskon) (data.sub_total * data.diskon / 100) : format_nominal(data.diskon) }}</td> -->
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td colspan="3" class="text-center font-weight-bold">Pajak</td>
                  <td class="font-weight-bold">{{ generateNominal(data.ppn, data.sub_total) }}</td>
                  <!-- <td class="font-weight-bold">{{ format_nominal(data.ppn) }}</td> -->
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td colspan="3" class="text-center font-weight-bold">Grand Total</td>
                  <td class="font-weight-bold">{{ format_nominal(data.total) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end mt-5">
              <div class="border-bottom border-dark mr-5 " style="height: 100px;width: 100px;">
                <p class="text-center">Hormat Kami</p>
              </div>
            </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer" style="display: block;">
          
        </div>
        <!-- /.card-footer-->
      </div>
    </div>
  </div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'

import { ref, onMounted, reactive, computed } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import format_nominal from '@/format_nominal'
import dayjs from 'dayjs'
import store from '@/store'

const route = useRoute()
const cabang = computed(() => store.getters['auth/cabang'])
const pemesanan_id = route.params.id
const data = ref([])
const pembeli = reactive({
  'nama' : '',
  'alamat' : '',
  'telepon' : ''
})
const penjual = reactive({
  'nama' : '',
  'sio' : '',
  'alamat' : '',
  'telepon' : ''
}) 
const getData = async () => {
  let response = await axios.get(`api/pembelian/getPembelianDetail/${pemesanan_id}/${cabang.value.tipe}`)
  console.log(response)
  if (response != 'kosong') {
    data.value = response.data

    pembeli.nama = response.data.pembeli.nama
    pembeli.alamat = response.data.pembeli.alamat
    pembeli.telepon = response.data.pembeli.telepon
    
    penjual.nama = response.data.supplier.nama
    penjual.alamat = response.data.supplier.alamat
    penjual.telepon = response.data.supplier.telepon
  } 
}

const generateNominal = (_, sub_total) => {
  if(_ <= 100) {
    let data = (sub_total * _) / 100
    return `${format_nominal(data)}`
  } else {
    return format_nominal(_)
  }
}

onMounted(() => {
  getData()
})
</script>